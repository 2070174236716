import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import {
    Accordion,
    AccordionBar,
    AccordionBody,
    AccordionItem
} from '../../lib/components/accordion';
import { Grid } from '../../lib/components/layout/grid';
import { List } from '../../lib/components/list';
import { SEO } from '../../lib/components/seo';
import { Heading } from '../../lib/components/typography/heading';
import { Paragraph } from '../../lib/components/typography/paragraph';
import { Box } from '../../lib/components/wrapper/box';
import { WithSidebarLayout } from '../../lib/ui/with-sidebar-layout';
import { coverageAreasRoute } from '../../routes/coverage-areas';

const Visayas = () => {
    const { pageContent, dealers } = useStaticQuery(query);

    return (
        <>
            <SEO
                title={pageContent.seo.title}
                description={pageContent.seo.description}
                image={pageContent.seo.image.file.url}
            />
            <WithSidebarLayout
                sidebar="coverage"
                sidebarNav={coverageAreasRoute}
                sidebarTitle="Coverage Areas"
                sidebarSticky={true}>
                <Grid item>
                    <Grid
                        container
                        base={false}
                        className={clsx('l-sm:grid-cols-2 l-sm:gap-x-5')}>
                        <Grid
                            item
                            className={clsx(
                                'l-sm:self-start l-sm:sticky l-sm:top-24'
                            )}>
                            <Heading
                                tagName="h1"
                                className="text-lg max-w-18ch">
                                {dealers.category} dealers and coverage
                            </Heading>

                            <Grid
                                container
                                base={false}
                                defaultGap={false}
                                className={clsx(
                                    'gap-2 mt-5',
                                    'm-lg:grid-cols-2'
                                )}>
                                <Box className="grid items-center justify-items-center bg-light-gray">
                                    <Paragraph
                                        className="text-lg leading-normal text-center"
                                        body={false}>
                                        {dealers.numberOfDealers}
                                    </Paragraph>
                                    <Paragraph className="text-center">
                                        No. of Dealers
                                    </Paragraph>
                                </Box>

                                <Box className="grid items-center justify-items-center bg-light-gray">
                                    <Paragraph
                                        className="text-lg leading-normal text-center"
                                        body={false}>
                                        {dealers.numberOfCoveredHospitals}
                                    </Paragraph>
                                    <Paragraph className="text-center">
                                        Covered Hospitals
                                    </Paragraph>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Accordion>
                                {dealers.dealers.map(
                                    (item: {
                                        id: string;
                                        cityRegion: string;
                                        content: [
                                            {
                                                id: string;
                                                hospital: string;
                                                contactPerson: string;
                                                contactNumber: string;
                                                email: string;
                                            }
                                        ];
                                    }) => {
                                        return (
                                            <AccordionItem key={item.id}>
                                                <AccordionBar
                                                    title={item.cityRegion}
                                                />
                                                <AccordionBody>
                                                    <List>
                                                        {item.content.map(
                                                            (contact) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            contact.id
                                                                        }>
                                                                        <div>
                                                                            <p className="font-normal">
                                                                                {
                                                                                    contact.hospital
                                                                                }
                                                                            </p>

                                                                            <div>
                                                                                {contact.contactPerson && (
                                                                                    <p>
                                                                                        {
                                                                                            contact.contactPerson
                                                                                        }
                                                                                    </p>
                                                                                )}
                                                                                {contact.contactNumber && (
                                                                                    <p>
                                                                                        Tel:&nbsp;
                                                                                        <a
                                                                                            href={`tel:${contact.contactNumber}`}>
                                                                                            {
                                                                                                contact.contactNumber
                                                                                            }
                                                                                        </a>
                                                                                    </p>
                                                                                )}
                                                                                {contact.email && (
                                                                                    <p>
                                                                                        Email:&nbsp;
                                                                                        <a
                                                                                            href={`mailto:${contact.email}`}>
                                                                                            {
                                                                                                contact.email
                                                                                            }
                                                                                        </a>
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </List>
                                                </AccordionBody>
                                            </AccordionItem>
                                        );
                                    }
                                )}
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
            </WithSidebarLayout>
        </>
    );
};

export default Visayas;

const query = graphql`
    {
        pageContent: contentfulCoverageAreasPage(
            slug: { eq: "/coverage-areas/visayas" }
        ) {
            seo {
                title
                description
                image {
                    file {
                        url
                    }
                }
            }
        }
        dealers: contentfulCoverageAreasDealersByCategory(
            category: { eq: "Visayas" }
        ) {
            category
            numberOfCoveredHospitals
            numberOfDealers
            dealers {
                id
                cityRegion
                content {
                    id
                    hospital
                    contactPerson
                    contactNumber
                    email
                }
            }
        }
    }
`;
